import { useDispatch } from "react-redux";
import socket from "../../../../../services/socket";
import { setReportBulkMessageStatus } from "../../../../../store/report/reportSlice";
import { Fragment, useEffect, useMemo } from "react";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import Iconify from "../../../../../components/Iconify";

export default function DetailItem({
    item,
    index,
    detail,
    nameSocketOn,
    dateName,
    phoneNumberName,
    failedName,
    type
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        {
            nameSocketOn &&
                socket.on(nameSocketOn, (res) => {
                    if (res.id === detail && res.number === item.number) {
                        dispatch(setReportBulkMessageStatus(res));
                    }
                });
        }
    }, []);

    const data = useMemo(() => {
        if (
            type === 'wa-broadcast' ||
            type === 'wa-schedule'
        ) {
            return [
                {
                    type: 'number',
                    value: index + 1,
                },
                {
                    type: 'phone',
                    value: item[phoneNumberName]
                },
                {
                    type: 'status',
                    value: item.status
                },
                {
                    type: 'time',
                    value: item[dateName]
                }
            ]
        } else if (type === 'cloud-broadcast') {
            return [
                {
                    type: 'number',
                    value: index + 1,
                },
                {
                    type: 'phone',
                    value: item[phoneNumberName]
                },
                {
                    type: 'note',
                    value: item.note
                },
                {
                    type: 'status',
                    value: item.status
                },
                {
                    type: 'time',
                    value: item[dateName]
                }
            ]
        }
        return []
    }, [
        type,
        dateName,
        item,
        phoneNumberName,
        index
    ])

    return data.length > 0 ?
        <TableRow key={index}>
            {data.map((value, i) => {
                const isStatus = value.type === 'status'
                const isDate = value.type === 'time'
                return (
                    <Fragment key={i}>
                        <TableCell align="left" sx={{
                            whiteSpace: isDate ? 'nowrap' : 'normal'
                        }}>
                            {value.value === failedName ? (
                                <Tooltip title={`${value.value.error ?? failedName}`}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                fontWeight: 700,
                                                color: "#F15A28",
                                                mr: 0.5,
                                            }}
                                        >
                                            {value.value}
                                        </Box>
                                        <Iconify
                                            icon={"material-symbols:info-outline-rounded"}
                                            color="#F15A28"
                                            width={12}
                                            height={12}
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </Box>
                                </Tooltip>
                            ) : (
                                value.value
                            )}
                        </TableCell>
                    </Fragment>
                )
            })}
        </TableRow>
        :
        <></>
}