import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDevices } from "../../../../store/device/deviceAction";
import toast from "react-hot-toast";
import { Stack } from "@mui/material";
import { addWabaTemplate, deleteWabaTemplate, fetchWabaTemplate, getAllWabaTemplate } from "../../../../store/waba-template/wabaTemplateAction";
import CreateTemplate from "./create-template";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import EditTemplate from "./edit-template";
import TableLists from "./TableLists";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

export default function TemplateMessageCloudAPI() {
    const [wabaDevice, setWabaDevice] = useState(null)
    const [loadingDeleteTemplate, setLoadingDeleteTemplate] = useState({
        loading: false,
        template_id: null
    })
    const [page, setPage] = useState(1)
    const [last_page, set_last_page] = useState(null)
    const [total, setTotal] = useState(0)
    // fetch
    const [loadingFetch, setLoadingFetch] = useState(false)

    const [statusPage, setStatusPage] = useState({
        status: 'TABLE-LISTS',
        title: 'Message Templates'
    })
    const [validateNextPage, setValidateNextPage] = useState({})
    // create template page
    // category
    const [currentCategory, setCurrentCategory] = useState({})
    const [radioInputValue, setRadioInputValue] = useState({})
    // name
    const [nameValue, setNameValue] = useState('')
    // languages
    const [languageValue, setLanguageValue] = useState(null)
    // value on edit template (create template page)
    const [detail, setDetail] = useState({})
    // headers
    const [headerValue, setHeaderValue] = useState({
        name: `There isn't any`, value: 'no',
    })
    // header text
    const [textHeader, setTextHeader] = useState({})
    const [totalVariableHeader, setTotalVariableHeader] = useState([])
    const [contentAddButton, setContentAddButton] = useState([])
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const dispatch = useDispatch()
    const { devices } = useSelector((state) => state.device)
    const { wabaTemplates } = useSelector((state) => state.wabaTemplate)

    const [currentWabaTemplates, setCurrentWabaTemplates] = useState(wabaTemplates)

    // const currentDevices = useMemo(()=>{
    //     if(devices?.length < 1){
    //         return []
    //     }
    //     let checkDuplicate = []
    //     const newDevice = devices.map((dev)=>{
    //         const isDuplicateName = checkDuplicate.find(item=>item.name == dev.name)
    //         checkDuplicate.push(dev)
    //         return {
    //             ...dev,
    //             name: isDuplicateName?.name ? `${dev.name} - ${dev.phone}` : dev.name
    //         }
    //     })
    //     return newDevice
    // }, [devices])

    async function getWabaDevices() {
        const params = {
            params: {
                platform: 'waba'
            }
        }
        const result = await dispatch(getDevices(params))
        if (result.type !== 'get-devices/fulfilled') {
            toast.error(result.payload.message)
        }
    }

    useEffect(() => {
        getWabaDevices()
    }, [])

    const currentDevices = useMemo(() => {
        return devices?.length >= 1
            ? devices.map((dev) => ({ ...dev, name: `${dev.name} - ${dev.phone}` }))
            : [];
    }, [devices]);

    async function loadWabaTemplate(device_key, getPage) {
        const data = {
            params: {
                device_key,
                page: getPage ?? page
            }
        }
        const result = await dispatch(getAllWabaTemplate(data))
        if (result.type == 'waba-template/fulfilled') {
            set_last_page(result.payload.pagination.last_page)
            setTotal(result.payload.pagination.total)
            setCurrentWabaTemplates(result.payload.data)
            return
        }
        toast.dismiss()
        toast.error(result?.payload?.message)
    }

    useEffect(() => {
        if (currentDevices?.length > 0) {
            setWabaDevice(currentDevices[0])
            loadWabaTemplate(currentDevices[0]?.device_key)
        }
    }, [currentDevices])

    function changeDevice(e, v) {
        setWabaDevice(v)
        if (v) {
            loadWabaTemplate(v.device_key, 1)
            // return
        }
        setPage(1)
        setTotal(0)
        set_last_page(null)
        setCurrentWabaTemplates([])
    }

    const rows = useMemo(() => {
        if (currentWabaTemplates?.length === 0) {
            return []
        }
        return currentWabaTemplates.map(item => ({ ...item, id: item.template_id }))
    }, [currentWabaTemplates])

    const handleDeleteMessageTemplates = useCallback(async (template_id) => {
        if (loadingDeleteTemplate.loading) {
            return
        }
        setLoadingDeleteTemplate({ loading: true, template_id })
        const result = await dispatch(deleteWabaTemplate({ template_id }))
        if (result.type == 'waba-template/delete/fulfilled') {
            toast.dismiss()
            toast.success(result?.payload?.message)
            // reset list templates
            const newWabaTemplates = currentWabaTemplates.filter((item) => item?.template_id !== template_id)
            setCurrentWabaTemplates(newWabaTemplates)
            setPage(1)
            loadWabaTemplate(wabaDevice?.device_key, 1)
        } else {
            toast.dismiss()
            toast.error(result?.payload?.message)
        }
        setLoadingDeleteTemplate({ loading: false, template_id: null })
    }, [
        loadingDeleteTemplate,
        wabaDevice,
        currentWabaTemplates
    ])

    const columns = [
        {
            field: 'name',
            headerName: 'Template Name',
            width: 260,
            renderCell: (params) => {
                const { row } = params;

                return row.name;
            },
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width: 200,
            renderCell: (params) => {
                const { row } = params;

                return row.slug;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
            renderCell: (params) => {
                const { row } = params;

                return row.status;
            },
        },
        {
            field: 'reason',
            headerName: 'Reason',
            width: 200,
            renderCell: (params) => {
                const { row } = params;

                return row.reason ?? '-';
            },
        },
        {
            field: 'created_at',
            headerName: 'Created_at',
            width: 200,
            renderCell: (params) => {
                const { row } = params;

                return row.created_at;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => {
                const { row } = params;

                return (
                    <Stack direction="row" spacing={1}>
                        <ButtonTooltip
                            title="Edit"
                            icon="akar-icons:edit"
                            color={loadingDeleteTemplate.template_id === row?.template_id ? 'rgba(26, 119, 111, 0.5)' : '#1A776F'}
                            disabled={loadingDeleteTemplate.template_id === row?.template_id}
                        />
                        <ButtonTooltip
                            title="Delete"
                            icon="fluent:delete-24-regular"
                            color={loadingDeleteTemplate.loading ? 'rgba(241, 90, 40, 0.5)' : '#F15A28'}
                            disabled={loadingDeleteTemplate.loading}
                            handleClick={() => handleDeleteMessageTemplates(row?.template_id)}
                        />
                    </Stack>
                );
            },
        },
    ]

    async function clickFetch() {
        setLoadingFetch(true)
        toast.loading('Please wait...')
        const data = {
            device_key: wabaDevice.device_key
        }
        const result = await dispatch(fetchWabaTemplate(data))
        if (
            result.type == 'waba-template/fetch/fulfilled' &&
            result.payload.result
        ) {
            toast.dismiss()
            toast.success(result.payload.message)
            setLoadingFetch(false)
            loadWabaTemplate(wabaDevice.device_key, 1)
            return
        }
        toast.dismiss()
        toast.error(result.payload.message)
        setLoadingFetch(false)
    }

    // create template logic
    const validateMarketingOnCustom = useMemo(() => {
        let err = {}
        if (totalVariableHeader.length > 0 && !totalVariableHeader[0]?.value?.trim()) {
            err.header_variable = 'err'
        }
        if (!detail?.body?.text?.trim()) {
            err.bodyText = 'err'
        }
        const check = detail?.body?.examples.filter(txt => !txt?.trim())
        if (
            detail?.body?.examples?.length > 0 &&
            check?.length > 0
        ) {
            err.examples = 'err'
        }
        if (contentAddButton.length > 0) {
            const isRejectionCheckbox = contentAddButton.find(item => {
                const get = item.type == 'quick-reply' && item.buttons?.find(act =>
                    act?.actions?.find(actData => actData?.selectionType == 'marketing-rejection' && actData?.value === false)
                )
                return get
            })
            if (isRejectionCheckbox) {
                err.reject = 'err'
            }
            const isVariableWebURL = contentAddButton.filter(item => {
                const get = item.type == 'call' && item.buttons?.find(act =>
                    act?.actions?.find(actData =>
                        actData?.selectionType == 'website' &&
                        actData?.type == 'variable' &&
                        !actData?.totalVariables[0]?.value?.trim()
                    )
                )
                return get
            })
            if (isVariableWebURL.length > 0) {
                err.web_url = 'err'
            }
        }
        if (Object.keys(err).length > 0) {
            return true
        }
        return false
    }, [detail, contentAddButton])

    const submitValidation = useMemo(() => {
        if (
            currentCategory?.category == 'Marketing' &&
            radioInputValue?.value == 'custom'
        ) {
            return loadingSubmit || validateMarketingOnCustom
        }
        return !loadingSubmit
    }, [
        currentCategory,
        radioInputValue,
        detail,
        contentAddButton,
        loadingSubmit
    ])

    // marketing in custom selection
    async function submitMarketingOnCustom() {
        setLoadingSubmit(true)

        const data = {
            device_key: wabaDevice?.device_key ?? null,
            name: nameValue,
            category: currentCategory?.category?.toLowerCase() ?? null,
            language: languageValue?.code,
            payload: {}
        }

        // header
        if (headerValue?.value == 'no') {
            data.payload.header_empty = []
        } else if (headerValue?.value == 'media') {
            data.payload.header_media = detail?.header_media
        } else if (headerValue?.value == 'text') {
            const header_text = {
                type: detail?.header_text?.type,
                text: textHeader?.value,
                examples: [detail?.header_text?.variable]
            }
            if (!detail?.header_text?.variable?.trim()) {
                delete header_text.examples
            }
            data.payload.header = header_text
        }
        // body
        if (detail?.body) {
            data.payload.body = {
                text: detail.body.text,
                examples: detail.body.examples
            }
        }
        // footer
        if (detail?.footer?.text?.trim()) {
            data.payload.footer = {
                footer: {
                    text: detail.footer.text
                }
            }
        }
        // buttons
        if (detail?.buttons?.length > 0) {
            let newButtons = []
            detail.buttons.forEach((item) => {
                item.actions.forEach(act => {
                    newButtons.push(act)
                })
            })
            newButtons = newButtons.map(item => {
                // web_url with dynamic
                if (item?.type == 'web_url' && item?.examples[0]?.trim()) {
                    return {
                        type: item.type,
                        text: item.text,
                        url: `${item.url}{{1}}`,
                        examples: item.examples
                    }
                } else if (item?.type == 'web_url') {
                    return {
                        type: item.type,
                        text: item.text,
                        url: item.url,
                        examples: item.examples
                    }
                }
                if (item?.type == 'call') {
                    return {
                        type: item.type,
                        text: item.text,
                        phone: item.phone
                    }
                }
                if (item?.type == 'copy') {
                    return {
                        type: item.type,
                        example: item.example
                    }
                }
                if (item?.type == 'reject') {
                    return {
                        type: item.type,
                        text: item.text
                    }
                }
                if (item?.type == 'custom') {
                    return {
                        type: 'quick_reply',
                        text: item.text
                    }
                }
                return item
            })
            data.payload.buttons = newButtons
        }

        const result = await dispatch(addWabaTemplate(data))
        if (result.type !== 'waba-template/add/rejected') {
            toast.dismiss()
            toast.success(result.payload?.message)
            setPage(1)
            setTotal(0)
            set_last_page(null)
            setCurrentWabaTemplates([])
            loadWabaTemplate(wabaDevice.device_key, 1)
            setStatusPage({
                status: 'TABLE-LISTS',
                title: 'Message Templates'
            })
            setCurrentCategory({})
            setRadioInputValue([])
            setNameValue('')
            setLoadingSubmit(false)
            return
        }
        toast.dismiss()
        toast.error(result.payload?.message)
        setLoadingSubmit(false)
    }

    function button1OnClick() {
        if (statusPage?.status == 'CREATE-TEMPLATE') {
            setStatusPage({
                status: 'EDIT-TEMPLATE',
                title: 'Edit Template'
            })
            setValidateNextPage({})
        } else if (
            statusPage?.status == 'EDIT-TEMPLATE' &&
            currentCategory?.category == 'Marketing' &&
            radioInputValue?.value == 'custom'
        ) {
            submitMarketingOnCustom()
        }
    }

    function buttonBackOnClick() {
        setPage(1)
        setTotal(0)
        set_last_page(null)
        setCurrentWabaTemplates([])
        loadWabaTemplate(wabaDevice.device_key, 1)
        setStatusPage({
            status: 'TABLE-LISTS',
            title: 'Message Templates'
        })
    }

    return (
        <ContainerWithSomeButton
            title={statusPage?.title}
            buttonBack={statusPage?.status !== 'TABLE-LISTS'}
            buttonBackTitle="Cancel"
            button1={statusPage?.status !== 'TABLE-LISTS'}
            button1Title={statusPage?.status !== 'EDIT-TEMPLATE' ? "Next" : 'Submit'}
            button1Disabled={validateNextPage?.next ? false : submitValidation}
            buttonBackOnClick={buttonBackOnClick}
            button1Icon="icon-park-outline:next"
            button1OnClick={button1OnClick}
        >
            {statusPage?.status == 'TABLE-LISTS' &&
                <TableLists
                    currentDevices={currentDevices}
                    wabaDevice={wabaDevice}
                    changeDevice={changeDevice}
                    loadingFetch={loadingFetch}
                    clickFetch={clickFetch}
                    total={total}
                    setStatusPage={setStatusPage}
                    page={page}
                    rows={rows}
                    columns={columns}
                    setPage={setPage}
                    loadWabaTemplate={loadWabaTemplate}
                    last_page={last_page}
                />
            }
            {statusPage?.status == 'CREATE-TEMPLATE' &&
                <CreateTemplate
                    setValidateNextPage={setValidateNextPage}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    radioInputValue={radioInputValue}
                    setRadioInputValue={setRadioInputValue}
                    nameValue={nameValue}
                    setNameValue={setNameValue}
                    languageValue={languageValue}
                    setLanguageValue={setLanguageValue}
                />
            }
            {statusPage?.status == 'EDIT-TEMPLATE' &&
                <EditTemplate
                    contentAddButton={contentAddButton}
                    setContentAddButton={setContentAddButton}
                    currentCategory={currentCategory}
                    radioInputValue={radioInputValue}
                    headerValue={headerValue}
                    textHeader={textHeader}
                    totalVariableHeader={totalVariableHeader}
                    setTotalVariableHeader={setTotalVariableHeader}
                    setTextHeader={setTextHeader}
                    setHeaderValue={setHeaderValue}
                    detail={detail}
                    setDetail={setDetail}
                />
            }
        </ContainerWithSomeButton>
    )
}