import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import BasicButton from "../../../components/button/BasicButton";
import ButtonWithIcon from "../../../components/button/ButtonWithIcon";
import Iconify from "../../../components/Iconify";
import CustomModal from "../../../components/modal/CustomModal";
import {
  deleteMultipleCloudFile,
  getCloudFile,
  uploadCloudFile,
} from "../../../store/cloudFile/cloudFileAction";
import { IconListResponsive } from "./Config";
import Upload from "./Upload";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}));

export default function CloudStorageSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get("page") ? queryParams.get("page") : 1;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const { plan } = useSelector((state) => state.user);
  const { cloudFiles, storageInfo } = useSelector((state) => state.cloudFile);

  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState([]);
  const [fileSelected, setFileSelected] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0, id: null })
  const copyButtonRef = useRef(null)

  const [openUploadModal, setOpenUploadModal] = React.useState(false);

  const buttonGroup = [
    {
      title: "Upload",
      icon: "ic:outline-file-upload",
      prev: () => {
        setOpenUploadModal(true);
      },
    },
    {
      title: "Delete",
      icon: null,
      prev: () => {
        handleDeleteMultipleCloudFile();
      },
    },
  ];

  const handleGetCloudFile = async () => {
    setIsLoading(true);

    const data = {
      params: {
        type: null,
        search: search,
        page: page,
      },
    };

    await dispatch(getCloudFile(data));

    setIsLoading(false);
  };

  const handleDeleteMultipleCloudFile = async () => {
    const data = {
      ids: fileSelected,
    };

    toast.loading("Please wait...");

    await dispatch(deleteMultipleCloudFile(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message)
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });

    setFileSelected([]);
    handleGetCloudFile();
  };

  const handleUploadCloudFile = async () => {
    await acceptedFiles.map(async (item) => {
      const data = new FormData();

      data.append("file", item);

      toast.loading("Please wait...");

      await dispatch(uploadCloudFile(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          handleGetCloudFile();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    });
  };

  const convertSize = (value) => {
    if (parseFloat(value).toFixed(2) > 1000) {
      return (parseFloat(value) / 1000).toFixed(2) + "GB";
    } else {
      return parseFloat(value).toFixed(2) + "MB";
    }
  };

  useEffect(() => {
    handleGetCloudFile();
  }, [search, page]);

  useEffect(() => {
    if (acceptedFiles) {
      handleUploadCloudFile();
    }
  }, [acceptedFiles]);

  const handleContextMenu = (e, id) => {
    e.preventDefault()
    setTooltipPosition({ x: e.pageX, y: e.pageY, id })
  };

  const handleTooltipClick = (e, item) => {
    navigator.clipboard.writeText(`https://new-client.realm.chat/cloud_storage/${item.file_url}`)
    toast.success('Url successfully copied')
  }

  const handleClickOutside = (e) => {
    if (!copyButtonRef.current.contains(e.target)) {
      e.stopPropagation()
      setTooltipPosition({ x: 0, y: 0, id: null })
    }
  }

  useEffect(() => {
    if (tooltipPosition.id) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
  }, [tooltipPosition.id])

  return (
    <>
      <Card
        style={{
          backgroundColor: "#FDFDFD",
          padding: "16px",
          borderRadius: "10px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              fontSize={{ xs: 20, sm: 24 }}
              fontWeight="700"
              color="#131313"
            >
              Cloud Storage
            </Typography>
          </Box>
          <IconListResponsive
            onClickAddIcon={() => setOpenUploadModal(true)}
            onClickDeleteIcon={() => handleDeleteMultipleCloudFile()}
          />
          <Stack
            spacing={1}
            display={{ xs: "none", sm: "flex", md: "flex" }}
            direction="row"
          >
            {buttonGroup.map((item, index) => {
              return (
                <Box display="flex" alignItems="center" key={index}>
                  <ButtonWithIcon
                    title={item.title}
                    icon={item.icon}
                    onClick={item.prev}
                  />
                </Box>
              );
            })}
          </Stack>
        </Box>
        <FormControl
          fullWidth
          sx={{ marginTop: 2, marginBottom: 2 }}
          onChange={(e) => setSearch(e.target.value)}
        >
          <OutlinedInput
            style={{ height: "40px" }}
            id="outlined-adornment-amount"
            placeholder="Search file"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon={"heroicons:magnifying-glass"}
                  color="#7B7B7B"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />
        </FormControl>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} sx={{ mb: 2 }}>
            <Box>
              <Box
                sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Typography fontSize={20} fontWeight="700">
                  Your Files
                </Typography>
              </Box>
              <div
                {...getRootProps({
                  className: "dropzone",
                  onClick: (e) => e.stopPropagation(),
                })}
              >
                <input {...getInputProps()} />

                {isLoading ? (
                  <Box sx={{ py: 2 }}>
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  </Box>
                ) : cloudFiles?.data?.length ? (
                  <Grid container spacing={2}>
                    {cloudFiles?.data?.map((item, index) => {
                      const selected = fileSelected.find((e) => e === item.id);

                      return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Box
                            onContextMenu={(e) => {
                              if (selected) {
                                handleContextMenu(e, item.id)
                              }
                            }}
                            onClick={() => {
                              setTooltipPosition({ x: 0, y: 0, id: null })
                              if (isFileSelected) {
                                setIsFileSelected(false);
                              } else {
                                setIsFileSelected(true);
                              }

                              if (selected) {
                                const fileSelectedRaw = fileSelected.filter(
                                  (item) => {
                                    return item !== selected;
                                  }
                                );

                                setFileSelected(fileSelectedRaw);
                              } else {
                                const fileSelectedRaw = fileSelected;

                                fileSelectedRaw.push(item.id);

                                setFileSelected(fileSelectedRaw);
                              }
                            }}
                          >
                            <Card
                              sx={
                                selected
                                  ? {
                                    border: "4px solid rgba(33,194,193,1)",
                                    opacity: "0.6",
                                    background: "#F5F5F5",
                                    position: 'relative'
                                  }
                                  : { background: "#F5F5F5" }
                              }
                            >
                              {tooltipPosition.id === item.id && (
                                <Tooltip
                                  open={true}
                                  title={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5,
                                        cursor: 'pointer'
                                      }}
                                      onClick={(e) => handleTooltipClick(e, item)}
                                      ref={copyButtonRef}
                                    >
                                      <Iconify
                                        icon="clarity:copy-line"
                                        color="#fff"
                                        height="18px"
                                        width="18px"
                                      />Copy to Clipboard
                                    </Box>
                                  }
                                  placement="right"
                                  arrow
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: 'offset',
                                        options: {
                                          offset: [30, 100],
                                        },
                                      },
                                    ],
                                  }}
                                  sx={{
                                    cursor: 'pointer',
                                    zIndex: 99999,
                                    right: 0,
                                  }}
                                >
                                </Tooltip>
                              )}
                              <CardActionArea>
                                {item.file_type === "image" ? (
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`https://new-client.realm.chat/cloud_storage/${item.file_url}`}
                                    loading="lazy"
                                  />
                                ) : item.file_type === "video" ? (
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`/cloud/video.png`}
                                    loading="lazy"
                                  />
                                ) : item.file_type === "audio" ? (
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`/cloud/audio.png`}
                                    loading="lazy"
                                  />
                                ) : item.file_type === "document" ? (
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`/cloud/document.png`}
                                    loading="lazy"
                                  />
                                ) : null}
                                <CardContent
                                  sx={{
                                    px: 2,
                                    py: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.file_type === "image" ? (
                                    <Iconify
                                      icon="material-symbols:image-rounded"
                                      color="#FFC107"
                                      height="18px"
                                      width="18px"
                                    />
                                  ) : item.file_type === "video" ? (
                                    <Iconify
                                      icon="fluent:video-clip-16-filled"
                                      color="#00BCD4"
                                      height="18px"
                                      width="18px"
                                    />
                                  ) : item.file_type === "audio" ? (
                                    <Iconify
                                      icon="material-symbols:music-video"
                                      color="#4AC367"
                                      height="18px"
                                      width="18px"
                                    />
                                  ) : item.file_type === "document" ? (
                                    <Iconify
                                      icon="mdi:drive-document"
                                      color="#8D6E63"
                                      height="18px"
                                      width="18px"
                                    />
                                  ) : null}

                                  <Typography
                                    gutterBottom
                                    fontSize={14}
                                    fontWeight={500}
                                    sx={{ mb: 0, ml: 1 }}
                                  >
                                    {item.file_name}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <ContentStyle
                          sx={{ textAlign: "center", alignItems: "center" }}
                        >
                          <Box
                            component="img"
                            src="/static/illustrations/upload-file.png"
                            sx={{ height: 260, mx: "auto", my: { xs: 2 } }}
                          />
                          <Typography
                            variant="h4"
                            paragraph
                            sx={{ mb: 0, textAlign: "center" }}
                          >
                            Drag and drop files here
                          </Typography>
                          <Typography
                            variant="p"
                            color="#7B7B7B"
                            sx={{ mb: 0, textAlign: "center" }}
                          >
                            or use the "upload" button
                          </Typography>
                        </ContentStyle>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Box
                  sx={{
                    mb: 2,
                    p: 2,
                    background:
                      "linear-gradient(154.66deg, rgba(147, 223, 147, 0.1) 5.17%, rgba(33, 194, 193, 0.1) 68.62%)",
                    borderRadius: "16px",
                  }}
                >
                  <Box sx={{ mb: 2, display: "flex" }}>
                    <Box
                      sx={{
                        height: "48px",
                        width: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#fff",
                        borderRadius: "50%",
                      }}
                    >
                      <Iconify
                        icon="material-symbols:workspace-premium-outline"
                        color="#21C2C1"
                        height="24px"
                        width="24px"
                      />
                    </Box>
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        fontSize={16}
                        fontWeight="600"
                        color="#7B7B7B"
                      >
                        Your Plan
                      </Typography>
                      <Typography
                        fontSize={20}
                        fontWeight="700"
                        color="#00B909"
                      >
                        {plan?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        fontSize={16}
                        fontWeight="500"
                        color="#1A776F"
                      >
                        <span style={{ fontSize: "20px", fontWeight: "700" }}>
                          {convertSize(storageInfo?.total_storage_used)}
                        </span>{" "}
                        of {convertSize(storageInfo?.total_storage ?? 0)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width:
                            Math.floor(
                              (parseFloat(storageInfo?.video?.size) /
                                parseFloat(storageInfo?.total_storage_used)) *
                              100
                            ) + "%",
                          height: "8px",
                          background: "#00BCD4",
                          borderRadius: "8px 0 0 8px",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width:
                            Math.floor(
                              (parseFloat(storageInfo?.audio?.size) /
                                parseFloat(storageInfo?.total_storage_used)) *
                              100
                            ) + "%",
                          height: "8px",
                          background: "#4AC367",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width:
                            Math.floor(
                              (parseFloat(storageInfo?.image?.size) /
                                parseFloat(storageInfo?.total_storage_used)) *
                              100
                            ) + "%",
                          height: "8px",
                          background: "#FFC107",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width:
                            Math.floor(
                              (parseFloat(storageInfo?.document?.size) /
                                parseFloat(storageInfo?.total_storage_used)) *
                              100
                            ) + "%",
                          height: "8px",
                          background: "#8D6E63",
                          borderRadius: "0 8px 8px 0",
                        }}
                      ></Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <BasicButton
                      title="Upgrade Storage"
                      onClick={() =>
                        toast("Coming Soon", {
                          type: "warning",
                          isLoading: false,
                          position: "top-right",
                          autoClose: 5000,
                          closeOnClick: true,
                          draggable: true,
                        })
                      }
                    />
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography fontSize={20} fontWeight="700">
                      File Type
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      pb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #CBC8C8",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "36px",
                          width: "36px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FFF5D7",
                          borderRadius: "50%",
                        }}
                      >
                        <Iconify
                          icon="material-symbols:image-rounded"
                          color="#FFC107"
                          height="20px"
                          width="20px"
                        />
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          fontSize={18}
                          fontWeight="600"
                          color="#7B7B7B"
                        >
                          Image
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight="500"
                          color="#7B7B7B"
                        >
                          {storageInfo?.image?.count} Files
                        </Typography>
                      </Box>
                    </Box>
                    <Typography fontSize={18} fontWeight="500" color="#7B7B7B">
                      {convertSize(storageInfo?.image?.size)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      pb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #CBC8C8",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "36px",
                          width: "36px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#E8F9FB",
                          borderRadius: "50%",
                        }}
                      >
                        <Iconify
                          icon="fluent:video-clip-16-filled"
                          color="#00BCD4"
                          height="20px"
                          width="20px"
                        />
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          fontSize={18}
                          fontWeight="600"
                          color="#7B7B7B"
                        >
                          Video
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight="500"
                          color="#7B7B7B"
                        >
                          {storageInfo?.video?.count} Files
                        </Typography>
                      </Box>
                    </Box>
                    <Typography fontSize={18} fontWeight="500" color="#7B7B7B">
                      {convertSize(storageInfo?.video?.size)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      pb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #CBC8C8",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "36px",
                          width: "36px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#E8F7EB",
                          borderRadius: "50%",
                        }}
                      >
                        <Iconify
                          icon="material-symbols:music-video"
                          color="#4AC367"
                          height="20px"
                          width="20px"
                        />
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          fontSize={18}
                          fontWeight="600"
                          color="#7B7B7B"
                        >
                          Audio
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight="500"
                          color="#7B7B7B"
                        >
                          {storageInfo?.audio?.count} Files
                        </Typography>
                      </Box>
                    </Box>
                    <Typography fontSize={18} fontWeight="500" color="#7B7B7B">
                      {convertSize(storageInfo?.audio?.size)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      pb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "36px",
                          width: "36px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#F1EDEB",
                          borderRadius: "50%",
                        }}
                      >
                        <Iconify
                          icon="mdi:drive-document"
                          color="#8D6E63"
                          height="20px"
                          width="20px"
                        />
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          fontSize={18}
                          fontWeight="600"
                          color="#7B7B7B"
                        >
                          Documents
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight="500"
                          color="#7B7B7B"
                        >
                          {storageInfo?.document?.count} Files
                        </Typography>
                      </Box>
                    </Box>
                    <Typography fontSize={18} fontWeight="500" color="#7B7B7B">
                      {convertSize(storageInfo?.document?.size)}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title="<"
              onClick={() => {
                navigate(
                  parseInt(page) > 1
                    ? `/dashboard/cloud-storage?page=${parseInt(page) - 1}`
                    : null
                );
              }}
              disabled={parseInt(page) === 1}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title=">"
              onClick={() => {
                navigate(
                  parseInt(page) < cloudFiles?.pagination?.last_page
                    ? `/dashboard/cloud-storage?page=${parseInt(page) + 1}`
                    : null
                );
              }}
              disabled={parseInt(page) >= cloudFiles?.pagination?.last_page}
            />
          </Box>
        </Box>
      </Card>
      <CustomModal
        handleClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
      >
        <Upload onCancle={() => setOpenUploadModal(false)} />
      </CustomModal>
    </>
  );
}
