import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../../../../components/Iconify";

export default function UpdateProfile({
    navigate,
    item,
}) {
    return (
        <Tooltip title="Update Profile">
            <IconButton
                onClick={() => navigate('/dashboard/app/device-update/' + item?.device_key)}
            >
                <Iconify
                    icon={"fa-solid:user-cog"}
                    color="#64d88b"
                    width={24}
                    height={24}
                />
            </IconButton>
        </Tooltip>
    )
}