import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { IconListDetailResponsive } from "../../schedule-message/Config";
import ButtonWithIcon from "../../../../../components/button/ButtonWithIcon";
import Iconify from "../../../../../components/Iconify";
import Scrollbar from "../../../../../components/Scrollbar";
import DetailItem from "../detail-item";
import EmptyBox from "../../../../../components/EmptyBox";
import palette from "../../../../../theme/palette";
import { Fragment } from "react";

export default function DetailView({
    detail,
    buttonGroupDetail,
    handleExportReport,
    allStatusData,
    substringTotal,
    isLoading,
    detailInformation,
    tableCellDetail,
    emptyBoxTitle1,
    emptyBoxTitle2,
    nameSocketOn,
    dateName,
    phoneNumberName,
    failedName,
    type
}) {
    return (
        <Card
            style={{
                backgroundColor: "#FDFDFD",
                padding: "16px",
                borderRadius: "10px",
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                pl={2}
                pr={1}
                alignItems="center"
            >
                <Box>
                    <Typography
                        fontSize={{ xs: 20, sm: 24 }}
                        fontWeight="700"
                        color="#131313"
                        sx={{ display: { xs: "none", md: "block" } }}
                    >
                        {detail}
                    </Typography>
                    <Typography
                        fontSize={{ xs: 20, sm: 24 }}
                        fontWeight="700"
                        color="#131313"
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        {detail.substring(0, substringTotal)} ...
                    </Typography>
                </Box>
                <IconListDetailResponsive
                    onClickExportIcon={() => handleExportReport()}
                />
                <Stack
                    spacing={1}
                    display={{ xs: "none", sm: "flex", md: "flex" }}
                    direction="row"
                >
                    {buttonGroupDetail.map((item, index) => {
                        return (
                            <Box display="flex" alignItems="center" key={index}>
                                <ButtonWithIcon
                                    title={item.title}
                                    icon={item.icon}
                                    onClick={item.prev}
                                />
                            </Box>
                        );
                    })}
                </Stack>
            </Box>

            <Box
                sx={{
                    mb: 2,
                    background:
                        "linear-gradient(154.66deg, rgba(147, 223, 147, 0.1) 5.17%, rgba(33, 194, 193, 0.1) 68.62%)",
                    borderRadius: "12px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {detailInformation?.length > 0 && detailInformation.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Box
                                sx={{
                                    display: "flex",
                                    p: 2,
                                }}>
                                <Box
                                    sx={{
                                        mr: 2,
                                        height: "48px",
                                        width: "48px",
                                        background: "#fff",
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Iconify
                                        icon={item.icon}
                                        color={item.colorIcon}
                                        width={36}
                                        height={36}
                                    />
                                </Box>
                                <Box>
                                    <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                                        {item.name}
                                    </Typography>
                                    <Typography fontSize="24px" fontWeight="700" color="#00B909">
                                        {item.value}
                                    </Typography>
                                </Box>
                            </Box>
                        </Fragment>
                    );
                })}
            </Box>

            {
                allStatusData ? (
                    <Scrollbar>
                        <TableContainer
                            component={Paper}
                            style={{ backgroundColor: "#FDFDFD" }}
                        >
                            <Table aria-label="simple table">
                                <TableHead
                                    style={{
                                        background: palette.gradients.custom,
                                    }}
                                >
                                    <TableRow>
                                        {tableCellDetail.map((item, index) => {
                                            return (
                                                <TableCell align="left" key={index}>
                                                    <Box
                                                        alignItems="center"
                                                        display="flex"
                                                        justifyContent={"space-between"}
                                                    >
                                                        <Typography color="#FDFDFD">{item}</Typography>
                                                    </Box>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                <Box
                                                    component="img"
                                                    src="/images/loading.svg"
                                                    alt="loading"
                                                    height="48px"
                                                    sx={{ mx: "auto" }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ) : allStatusData.length ? (
                                        allStatusData.map((item, index) => {
                                            return (
                                                <DetailItem
                                                    item={item}
                                                    index={index}
                                                    detail={detail}
                                                    key={index}
                                                    nameSocketOn={nameSocketOn}
                                                    dateName={dateName}
                                                    phoneNumberName={phoneNumberName}
                                                    failedName={failedName}
                                                    type={type}
                                                />
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={4}>
                                                Data Not Found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                ) : (
                    <EmptyBox
                        title1={emptyBoxTitle1}
                        title2={emptyBoxTitle2}
                    />
                )
            }
        </Card >
    );
}