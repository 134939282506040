//component
import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import Container from "../../../../components/containers/Container";
import EmptyBox from "../../../../components/EmptyBox";
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import { cancelScheduleMessage } from "../../../../store/marketing/marketingAction";
import {
  exportReport,
  getReportScheduleMessage,
  getReportScheduleMessageStatus,
} from "../../../../store/report/reportAction";
import {
  resetReportMessageStatus,
} from "../../../../store/report/reportSlice";
import palette from "../../../../theme/palette";
import DetailView from "../components/detail-view";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const tableCellList = ["Bulk ID", "Date", "From", "Status", "Action"];

const tableCellDetail = ["No", "Phone Number", "Status", "Time"];

export default function ScheduleMessage() {
  const dispatch = useDispatch();

  const { plan } = useSelector((state) => state.user);
  const { scheduleMessageAll, scheduleMessageStatusAll, isDetail } =
    useSelector((state) => state.report);

  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [detail, setDetail] = useState(null);

  const buttonGroupDetail = [
    {
      title: "Export",
      icon: null,
      prev: () => handleExportReport(),
    },
  ];

  const handleGetReportsScheduleMessage = async (data) => {
    setIsLoading(true);

    await dispatch(getReportScheduleMessage(data));

    setIsLoading(false);
  };

  const handleGetScheduleMessageStatus = async (item) => {
    setIsLoading(true);

    await dispatch(getReportScheduleMessageStatus({ scheduleId: item }));

    setIsLoading(false);
  };

  const handleCancelScheduleMessage = async (data) => {
    toast.loading("Please wait...");

    await dispatch(cancelScheduleMessage(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message)
      } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });
  };

  const handleExportReport = async () => {
    toast.loading("Please wait...");

    const dataJSON = {
      id: detail,
    };

    await dispatch(exportReport(dataJSON)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success("Export Report Successfully ...");
      } else {
        toast.dismiss();
        toast.error("Export Report Failed ...");
      }

      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Komers Report WhatsApp Schedule.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        search: search,
      },
    };

    handleGetReportsScheduleMessage(data);
  }, [search]);

  useEffect(() => {
    if (detail) {
      handleGetScheduleMessageStatus(detail);
    }
  }, [detail]);

  useEffect(() => {
    return () => {
      dispatch(resetReportMessageStatus());
    };
  }, []);

  useEffect(() => {
    setDetail(null);
  }, [isDetail]);

  // var gmtDateStr = ;
  // var gmtDate = new Date(gmtDateStr + " GMT");
  // var localDateStr = gmtDate.toLocaleString();
  // console.log(localDateStr);

  if (plan?.is_schedulemessage === "1") {
    if (detail) {
      const detailInformation = [
        {
          name: 'Total Message',
          value: scheduleMessageStatusAll.length,
          icon: 'uil:comment-alt-message',
          colorIcon: "#7B7B7B"
        },
        {
          name: 'Sent',
          value: scheduleMessageStatusAll.filter((e) => e.status === "Sent").length,
          icon: 'material-symbols:check',
          colorIcon: "#7B7B7B"
        },
        {
          name: 'Delivered',
          value: scheduleMessageStatusAll.filter((e) => e.status === "Delivered").length,
          icon: 'material-symbols:done-all',
          colorIcon: "#7B7B7B"
        },
        {
          name: 'Read',
          value: scheduleMessageStatusAll.filter((e) => e.status === "Read").length,
          icon: 'material-symbols:done-all',
          colorIcon: '#21C2C1'
        },
        {
          name: 'Replied',
          value: scheduleMessageStatusAll.filter((e) => e.status === "Replied").length,
          icon: 'ph:arrow-clockwise-bold',
          colorIcon: "#7B7B7B"
        },
        {
          name: 'Error',
          value: scheduleMessageStatusAll.filter((e) => e.status === "Error").length,
          icon: 'mdi:clock-time-four-outline',
          colorIcon: "#7B7B7B"
        },
      ]

      return (
        <DetailView
          detail={detail}
          handleExportReport={handleExportReport}
          buttonGroupDetail={buttonGroupDetail}
          allStatusData={scheduleMessageStatusAll}
          substringTotal={12}
          isLoading={isLoading}
          tableCellDetail={tableCellDetail}
          detailInformation={detailInformation}
          emptyBoxTitle1="No WhatsApp Schedule Database "
          emptyBoxTitle2="Generate now at"
          nameSocketOn="schedule-update"
          dateName='timestamp'
          phoneNumberName="number"
          failedName="Error"
          type="wa-schedule"
        />
      )
    } else {
      return (
        <Card
          style={{
            backgroundColor: "#FDFDFD",
            padding: "16px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
            pl={2}
            pr={1}
            alignItems="center"
          >
            <Box>
              <Typography
                fontSize={{ xs: 20, sm: 24 }}
                fontWeight="700"
                color="#131313"
              >
                WhatsApp Schedule
              </Typography>
            </Box>
          </Box>
          <FormControl
            fullWidth
            sx={{ marginTop: 2, marginBottom: 2 }}
            onChange={(e) => setSearch(e.target.value)}
          >
            <OutlinedInput
              style={{ height: "40px" }}
              id="outlined-adornment-amount"
              placeholder="Search contact"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={"heroicons:magnifying-glass"}
                    color="#7B7B7B"
                    width={24}
                    height={24}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          {scheduleMessageAll ? (
            <Scrollbar>
              <TableContainer
                component={Paper}
                style={{ backgroundColor: "#FDFDFD" }}
              >
                <Table aria-label="simple table">
                  <TableHead
                    style={{
                      background: palette.gradients.custom,
                    }}
                  >
                    <TableRow>
                      {tableCellList.map((item, index) => {
                        return (
                          <TableCell align="left" key={index}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography color="#FDFDFD">{item}</Typography>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Box
                            component="img"
                            src="/images/loading.svg"
                            alt="loading"
                            height="48px"
                            sx={{ mx: "auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : scheduleMessageAll.length ? (
                      scheduleMessageAll.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{item.schedule_id}</TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {(() => {
                              const gmtDateStr = item.created_at;
                              const splitDate = gmtDateStr?.split(' ')
                              const getNormalDate = `${splitDate[0]} ${splitDate[1]}`
                              const gmtDate = new Date(getNormalDate + " GMT");
                              const localDateStr = gmtDate.toLocaleString();
                              //console.log(localDateStr); // This logs the converted date to the browser's console.
                              return localDateStr; // Return the converted date for display.
                            })()}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item.device_info ? item.device_info?.phone : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              {item.status === "Stopped" ? (
                                <Badge color="error" badgeContent={item.status}>
                                  <></>
                                </Badge>
                              ) : item.status === "Success" ? (
                                <Badge
                                  color="primary"
                                  badgeContent={item.status}
                                >
                                  <></>
                                </Badge>
                              ) : (
                                <Badge
                                  color="warning"
                                  badgeContent={item.status}
                                >
                                  <></>
                                </Badge>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Box sx={{ mr: 1 }}>
                              <ButtonWithIcon
                                title="View"
                                icon="mdi:card-search-outline"
                                onClick={() => setDetail(item.schedule_id)}
                              />
                            </Box>
                            {item.status === "Pending" ||
                              item.status === "Processing" ? (
                              <ButtonWithIcon
                                title="Stop"
                                sx={{ background: "#F15A28" }}
                                onClick={() =>
                                  handleCancelScheduleMessage({
                                    scheduleId: item.schedule_id,
                                  })
                                }
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          Data Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : (
            <EmptyBox title1="No WhatsApp Broadcast Database" />
          )}
        </Card>
      );
    }
  } else {
    return (
      <Container title="WhatsApp Schedule Report">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
