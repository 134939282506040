import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../Iconify";

export default function ButtonTooltip({
    title,
    icon,
    height = 24,
    width = 24,
    color = '#1A776F',
    handleClick,
    disabled = false
}) {
    return (
        <Tooltip title={title}>
            <IconButton
                onClick={handleClick}
                disabled={disabled}
            >
                <Iconify
                    icon={icon}
                    color={color}
                    width={width}
                    height={height}
                />
            </IconButton>
        </Tooltip>
    )
}