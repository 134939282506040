import { Box, Typography } from "@mui/material";
import MenuWhatsAppWeb from "./whatsapp";
import MenuCloudAPICatalogSetting from "./cloud-api";
import MenuCloudAPI from "./cloud-api/NotCatalogListing";
import MenuInstagramCatalogSetting from "./instagram";
import MenuInstagram from "./instagram/NotCatalogListing";
import MenuEmail from "./email";
import MenuTelegram from "./telegram";
import MenuFacebook from "./facebook";
import UpdateProfile from "./cloud-api/UpdateProfile";

export default function DesktopMode({
    icon,
    wabaStatus,
    item,
    status,
    platform,
    navigate,
    isFarming,
    login,
    handleLogoutDevice,
    handleGetQrDevice,
    setQrModal,
    handleDeleteFarming,
    handleCreateFarming,
    handleDeleteDevice,
    handleUpdateCatalogSetting,
    handleUpdateCatalogSettingInstagram,
    handleAddCatalogWaba,
    handleAddCatalogInstagram,
    handleUnlinkDevice,
    handleAddTelegramLink,
    borderRadius,
    padding,
    borderBottom
}) {
    return (
        <Box
            sx={{
                background: '#EEF0FA',
                borderRadius: borderRadius,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center",
                padding: padding,
                borderBottom: borderBottom
            }}
            mb={1}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Box
                    mx={1}
                    component="img"
                    src={`/device/${icon}`}
                    sx={{
                        width: { xs: "35px", md: "45px" },
                        height: { xs: "35px", md: "45px" },
                        objectFit: "contain",
                    }}
                />

                <Box
                    overflow="hidden"
                >
                    <Typography
                        fontSize={{ xs: 14, sm: 16, md: 16 }}
                        whiteSpace="normal"
                        fontWeight="600"
                        color="#131313"
                    >
                        {wabaStatus ?
                            `${item.name} [ ${wabaStatus?.verified_name} - ${wabaStatus?.code_verification_status} ]`
                            : item.name
                        }
                    </Typography>
                    <Typography
                        fontSize={{ xs: 12, sm: 14, md: 14 }}
                        fontWeight="400"
                        color="#17454780"
                    >
                        {item.phone}
                    </Typography>
                    <Typography
                        fontSize={{ xs: 12, sm: 14, md: 14 }}
                        fontWeight="400"
                        color="#17454780"
                    >
                        {item.device_key}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    gap: 0.5
                }}
                ml={2}
            >
                {platform == 'waba' &&
                    <UpdateProfile
                        item={item}
                        navigate={navigate}
                    />
                }
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box>
                        <Typography
                            fontSize={14}
                            color={
                                status === "Connected"
                                    ? "#37E849"
                                    : status === "Disconnected" || status === "Logged Out"
                                        ? "#F15A28"
                                        : "#f5cd3d"
                            }
                        >
                            {status}
                        </Typography>
                    </Box>
                    {platform == 'whatsapp' && item?.status !== '1' &&
                        <MenuWhatsAppWeb
                            status={status}
                            isFarming={isFarming}
                            login={login}
                            handleLogoutDevice={handleLogoutDevice}
                            handleGetQrDevice={handleGetQrDevice}
                            setQrModal={setQrModal}
                            handleCreateFarming={handleCreateFarming}
                            handleDeleteFarming={handleDeleteFarming}
                            handleDeleteDevice={handleDeleteDevice}
                        />
                    }
                    {platform == 'waba' && item?.catalog_id &&
                        <MenuCloudAPICatalogSetting
                            status={status}
                            navigate={navigate}
                            item={item}
                            handleUpdateCatalogSetting={handleUpdateCatalogSetting}
                        />
                    }
                    {platform == 'waba' && !item?.catalog_id &&
                        <MenuCloudAPI handleAddCatalogWaba={handleAddCatalogWaba} />
                    }
                    {platform == 'instagram' && item?.catalog_id &&
                        <MenuInstagramCatalogSetting
                            status={status}
                            navigate={navigate}
                            item={item}
                            handleUpdateCatalogSetting={handleUpdateCatalogSetting}
                        />
                    }
                    {platform == 'instagram' && !item?.catalog_id &&
                        <MenuInstagram handleAddCatalogInstagram={handleAddCatalogInstagram} />
                    }
                    {platform == 'email' &&
                        <MenuEmail handleDeleteDevice={handleDeleteDevice} />
                    }
                    {platform == 'facebook' &&
                        <MenuFacebook
                            status={status}
                            isFarming={isFarming}
                            login={login}
                            handleLogoutDevice={handleLogoutDevice}
                            handleGetQrDevice={handleGetQrDevice}
                            setQrModal={setQrModal}
                            handleCreateFarming={handleCreateFarming}
                            handleDeleteFarming={handleDeleteFarming}
                            handleDeleteDevice={handleDeleteDevice}
                        />
                    }
                    {platform == 'telegram' &&
                        <MenuTelegram
                            item={item}
                            handleUnlinkDevice={handleUnlinkDevice}
                            handleAddTelegramLink={handleAddTelegramLink}
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}